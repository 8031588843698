
<template>


    <div>
        <div class="flex mb-3">
            <h1>Mantenimiento</h1>
            <Button label="Agregar" @click="showModal = true"  class="ml-auto"/>
        </div>


        <Dialog header="Agendar mantenimiento" v-model:visible="showModal" :style="{width: '50vw'}">

            <div class="formgrid grid">
                <div class="field col">
                <label for="detail">Maquina</label>
                <Dropdown
                  id="client"
                  v-model="form.machine_id"
                  :options="machines"
                  class="w-full"
                  optionLabel="name"
                  required="true"
                  autofocus
                />
              </div>
<div class="field col">
                <label for="detail">Tipo</label>

                        <div class="field-radiobutton">
            <RadioButton id="city1" name="city" value="Limpieza" v-model="form.title" />
            <label for="city1">Limpieza</label>
        </div>
        <div class="field-radiobutton">
            <RadioButton id="city2" name="city" value="Calibrado" v-model="form.title" />
            <label for="city2">Calibrado</label>
        </div>
        <div class="field-radiobutton">
            <RadioButton id="city3" name="city" value="Reparación" v-model="form.title" />
            <label for="city3">Reparación</label>
        </div>
</div>
            <div class="field col">
                <label for="time24"> Inicia </label>
                <br />
                <Calendar id="time24" dateFormat="dd.mm.yy" v-model="form.start" :showTime="false" :showSeconds="false" />
                <!-- <br />
                <label for="time24"> Finaliza </label>
                <br />
                <Calendar id="time24" v-model="form.end" :showTime="true" :showSeconds="false" /> -->
                
            </div>
    </div>
              <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="showModal = false"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="agendate"
            />
          </template>
        </Dialog>

        <FullCalendar id="target" ref="calendar"  :events="events" :options="options"
         />
    </div>
</template>

<script>
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventService from '../services/EventService';
import esLocale from '@fullcalendar/core/locales/es';

import CrudService from "../services/crud.service";

export default {
    data() {
        return {
            currentsEvents: [],
            form: {
                start: '',
                color: '',
                title: '',
            },
            showModal: false,
            machines: [],
            options: {
                locale: esLocale,
                plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialDate : '2022-04-08',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                //editable: true,
                //selectable:true, 
                selectMirror: true, 
                dayMaxEvents: true,
            },
            events: null,
            backendEvents: null,
        };
    },
    eventService: null,
    created() {
        this.eventService = new EventService();
    },
    mounted() {
        this.eventService.getEvents().then(data => this.backendEvents = data).then(() => this.events = this.backendEvents);
        this.getMachines();
    },
    methods: {
        handleEvents(events) {
            this.events = events
        },
        agendate() {
           
                let start = new Date(this.form.start);
                // set 0 hours
                start.setHours(0,0,0,0);

                //format to ISO
                let startISO = start.toISOString();

                //remove all timezone info
                startISO = startISO.substring(0, startISO.indexOf('T'));
                
                
                this.form.machine = this.form.machine_id
                //this.form.title = this.form.title
                //this.events.push(this.form);
                let whatcolor = "#fff"
                if(this.form.title == "Limpieza"){
                    whatcolor = "#2aa34fed"
                }
                if(this.form.title == "Calibrado"){
                    whatcolor = "#f5b746ed"
                }
                if(this.form.title == "Reparación"){
                    whatcolor = "#EF5350"
                }


                let data = {
                    machine: this.form.machine.id,
                    title: this.form.machine.name + " " + this.form.title,
                    date: startISO,
                    color: whatcolor,
                }

                this.eventService.addEvent(data).then(data => this.backendEvents = data).then(() =>{ window.location.reload()});
                //this.handleEvents(this.events);
                // // add 0 to month and day if less than 10
                // let month = (start.getMonth() + 1) < 10 ? '0' + (start.getMonth() + 1) : (start.getMonth() + 1);
                // let day = start.getDate() < 10 ? '0' + start.getDate() : start.getDate();
                // let startDate = start.getFullYear() + '-' + month + '-' + day;
                // let startTime = start.getHours() + ':' + start.getMinutes() + ':' + start.getSeconds();

                // this.form.start = startDate + 'T' + startTime;

            //this.currentsEvents = this.events;
            //this.currentEvents.push(this.form);
            //this.form = null;
            //this.events = this.currentEvents;
            // this.eventService.addEvent(this.form).then(() => {
            //     this.showModal = false;
            //     this.eventService.getEvents().then(data => this.events = data);
            // });
        },
        getMachines() {
        CrudService.getCRUD("maquinaria").then((response) => {
            this.machines = response;
        });
    },
    }
}
</script>

<style scoped>
@media screen and (max-width: 960px) {
    ::v-deep(.fc-header-toolbar) {
        display: flex;
        flex-wrap: wrap;
    }

    
}

::v-deep(.fc-event-main) {
        font-size: 20px;
        text-align: center;
    }
</style>